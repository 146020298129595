<template>
  <div class="modal edit" style="height: calc(100vh + 20px); top: -20px; background: #161616">
    <div class="modal__block">
      <div class="list__info modal-list" style="display:flex; align-items: flex-start">
        <div>{{ $t('edit_campaign_url') }} ID: {{ item.id }}</div>
      </div>
      <div class="list__details" style="margin-top: 0px">
        <div class="input__block">
          <p>{{ $t('new_campaign_url') }}</p>
          <textarea
            :class="{'error': isError}"
            id="input"
            class="input textarea"
            v-model="newUrl"
            placeholder="..."
            autocomplete="off"
            autocorrect="off"
            spellcheck="false"
          />
          <p class="example">
            <span style="color: white">{{ $t('example') }}:</span> https://domain.com/key?lead_id={lead_id}&sub1={sub1}&sub2={sub2}&sub3={sub3}&sub4={sub4}&sub5={sub5}&sub6={sub6}
          </p>
        </div>
      </div>
      <div class="btn__group" style="margin-top: 15px">
        <button
          @click="editUrl"
        >
          {{ $t('update') }}
        </button>
        <button @click="cancel">
          {{ $t('back') }}
        </button>
      </div>
      <!--      <div class="btn__group" style="margin-top: 5px">-->
      <!--        <button>-->
      <!--          Push Menu-->
      <!--        </button>-->
      <!--        <button>-->
      <!--          cloaka-->
      <!--        </button>-->
      <!--      </div>-->
    </div>
  </div>
</template>
<script>

export default {
  name: 'modal-edit-campaign',
  props: ['item'],
  data() {
    return {
      newUrl: this.item.campaign_url,
      isError: false,
    }
  },
  methods: {
    cancel() {
      this.$emit("close");
    },
    editUrl() {
      let testCases = [
        "lead_id={lead_id}&sub1={sub1}&sub4={sub4}&sub5={sub5}&sub6={sub6}&sub3={sub3}&sub2={sub2}",
        "lead_id={lead_id}"
      ];
      let cont = false;

      testCases.filter((item) => {
        if (this.newUrl.includes(item)) {
          cont = true;
        }
        console.log('cnt', cont);
      })
      if (cont) {
        window.Telegram.WebApp.sendData(`edit_link_set_campaign_url;${this.item.id};${this.newUrl}`);
      } else {
        this.isError = true;
        setTimeout(()=>{this.isError = false;}, 3000)
      }
    }
  },
}
</script>

<style lang="scss" scoped>
.input {
  width: 299px;
  overflow: scroll;
  white-space: nowrap;
  background: #242424;
  padding: 12px 13px;
  border-radius: 10px;
  margin-top: 5px;
  border: none;
  color: white;

  &.textarea {
    max-width: 299px!important;
    white-space: unset;
    height: 80px
  }

  &__block {
    font-size: 12px;
    text-align: left;
    margin-top: 10px;
  }
}

.modal-list {
  margin-top: -10px;
}

.error {
  border: 1px solid #ff2a00;
}
.example {
  font-size: 9px;
  margin-top: 5px;
  line-height: 12px;
  color: #d9d9d9;
}
</style>